<template>
  <div class="body-login">
    <div class="content-overlay" />
    <div class="content-wrapper">
      <div class="content-header row" />
      <div class="content-body">
        <div class="auth-wrapper auth-cover">
          <div class="auth-inner row m-0">
            <a
              class="brand-logo"
              href="https://www.icrea.cat"
            />
            <div
              class="d-none d-lg-flex col-lg-8 align-items-center p-5"
              :style="{'background': 'url(' + require('@/assets/images/illustration/bg-login.svg') + ') center center no-repeat', 'background-size': 'cover'}"
            >
              <div class="w-100 d-lg-flex align-items-center justify-content-center flex-column px-5">
                <div class="mb-4">
                  <img
                    class="img-fluid"
                    :src="require('@/assets/images/logo/logo-icrea-white-login.svg')"
                    alt="ICREA"
                  >
                </div>
                <!-- <div class="quote">
                  <h1><em>"You never fail until you stop trying"</em></h1>
                  <hr>
                  <p>Albert Einstein</p>
                </div> -->
              </div>
            </div>
            <!-- /Left Text-->
            <!-- Login-->
            <div
              v-if="!loading"
              class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5"
            >
              <div
                v-if="submitted"
                class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto"
              >
                <p
                  v-if="status"
                  class="card-text mb-2"
                >
                  Thank you for your acceptance. In a while your are going to receive an email to enter to our intranet (please check your spam mailbox)
                </p>
                <p
                  v-if="!status"
                  class="card-text mb-2"
                >
                  Thank you for your answer.
                </p>
              </div>
              <div
                v-if="!submitted"
                class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto"
              >
                <h2 class="card-title fw-bold mb-1">
                  Welcome to ICREA
                </h2>
                <p
                  v-if="call"
                  class="card-text mb-2"
                >
                  Call: <strong>{{ call }}</strong>
                </p>
                <p
                  v-if="peer"
                  class="card-text mb-2"
                >
                  Peer: <strong>Dr. {{ peer }}</strong>
                </p>
                <p
                  v-if="researcher"
                  class="card-text mb-2"
                >
                  Researcher: <strong>Dr. {{ researcher }}</strong>
                </p>
                <p
                  v-if="$route.params.status == 'refuse'"
                  class="card-text mb-2"
                >
                  Please, right down the reason for declining (optional)
                </p>
                <div
                  v-if="$route.params.status == 'refuse'"
                  class="mb-1"
                >
                  <textarea
                    v-model="declinedReason"
                    class="form-control"
                    :min-height="30"
                  />
                </div>
                <p
                  v-if="$route.params.status == 'accept'"
                  class="card-text mb-2"
                >
                  Please, confirm the peer acceptance
                </p>
                <a
                  v-if="!loading"
                  href="#"
                  class="btn btn-primary w-100"
                  tabindex="5"
                  data-bs-toggle="modal"
                  data-bs-target="#modalAcceptPrivacy"
                  @click="submit"
                >{{ $route.params.status == 'accept' ? 'Accept' : 'Decline' }}</a>
                <b-button
                  v-else
                  type="submit"
                  disabled
                >
                  <div class="loading">
                    <div class="effect-1 effects" />
                    <div class="effect-2 effects" />
                    <div class="effect-3 effects" />
                  </div>
                </b-button>
              </div>
            </div>
            <!-- /Login-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

require('@core/scss/core.scss')
require('@/assets/scss/style.scss')

export default {
  data() {
    return {
      declinedReason: null,
      call: null,
      researcher: null,
      peer: null,
      identifier: null,
      status: null,
      submitted: null,
      loading: true,
      sendedEmail: false,
    }
  },
  async mounted() {
    this.reload()
  },
  methods: {
    async reload() {
      const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users/parse-payload?payload=${this.$route.query.payload}`)
      this.loading = false
      if (resp.data.submitted) {
        this.declinedReason = resp.data.declined_reason
        this.status = resp.data.status
        this.submitted = resp.data.submitted
        this.sendedEmail = resp.data.sended
      } else {
        this.call = resp.data.convo
        this.researcher = resp.data.researcher
        this.peer = resp.data.name
      }
    },
    async submit() {
      const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users/submit-peer-response`, { payload: this.$route.query.payload, submit: this.$route.params.status === 'accept', declinedReason: this.declinedReason })
      if (resp.status === 200) {
        this.sendedEmail = resp.data.sended
        this.reload()
      } else {
        Vue.swal('Error on saving response', '', 'error')
      }
    },
  },

}
</script>
